import React from "react"

import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Puppy from "../components/puppy"

const Puppies = ({ data }) => {
  const puppies = data.images.puppies
  console.log(puppies)
  return (
    <Layout>
      <SEO title="Available Puppies" />

      <section className="pt-3 pb-3">
        <div className="container mx-auto">
          <div
            className="card text-center mb-5"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1555505019-8c3f1c4aba5f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80)",
            }}
          >
            <div className="card-header text-white font-weight-bold">
              Under The Ocean Litter
            </div>

            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/20a969e8cc7a38763c521c8b67ad7d95/14b42/crush.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Crush{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i style={{ color: "blue" }}>Available</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/478705d77b55cad2bee5eb3f998a7e1f/14b42/spongebob.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Spongebob{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i style={{ color: "blue" }}>Available</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/583870fffa6b26e274496fce321ee1c1/14b42/sharky.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Sharky{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i style={{ color: "blue" }}>Available</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/005b513419954f8712244145104ebd15/14b42/patrick.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Patrick{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i style={{ color: "blue" }}>Available</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/fb714a19ddab50f78ef353ad9947b3b9/14b42/marvin.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Marvin{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/c57e0ad616ab9acd90187bfc9e9b62be/14b42/pearl.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Pearl{" "}
                      <i class="fas fa-venus" style={{ color: "pink" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/9c621b8fc5830608ed9be43dfe9d23a5/14b42/star.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Star{" "}
                      <i class="fas fa-venus" style={{ color: "pink" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/67653eb70ef42282e12e757d05de5102/14b42/squirt.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Squirt{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/bebcab598045196554645871e6427ce4/14b42/nemo.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Nemo{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/cb3425d00624ab93d6d5a4299a1bebae/14b42/01-pinguino.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Pinguino{" "}
                      <i class="fas fa-mars" style={{ color: "#8cc1fa" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>

                <div className="col-md-3 mx-3 mb-3 p-1 photo photo2">
                  <div className="card">
                    <img
                      src="/static/ad496d2a46517cfc4346f9faab44acad/14b42/shelly.jpg"
                      className="card-img-top"
                    />
                    <h3 className="card-title" style={{ color: "#cab683" }}>
                      Shelly{" "}
                      <i class="fas fa-venus" style={{ color: "pink" }}></i>
                    </h3>
                    <i>Found wonderful home</i>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-footer text-muted ">
              <p className="text-white">x puppies remaining</p>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Puppies

export const query = graphql`
  query {
    images: allFile(
      filter: { relativeDirectory: { eq: "puppies/2021-02-19" } }
      sort: { fields: name, order: DESC }
    ) {
      puppies: edges {
        puppy: node {
          name
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
